import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import preferencesReducer, {
  fetchPreferencesEpic,
  updatePreferencesEpic,
} from "../slice/preferencesSlice";
import contactDetailsReducer, {
  fetchContactDetailsEpic,
} from "../slice/contactDetailsSlice";

const epicMiddleware = createEpicMiddleware();

export const rootEpic = combineEpics<AnyAction>(
  fetchPreferencesEpic,
  updatePreferencesEpic,
  fetchContactDetailsEpic
);

export const store = configureStore({
  reducer: {
    commPreferences: preferencesReducer,
    contactDetails: contactDetailsReducer,
  },
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
