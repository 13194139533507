import { Input, Box, Heading, Columns, Card } from "@myob/myob-widgets";
import { selectContactDetails } from "../../slice/contactDetailsSlice";
import { useAppSelector } from "../../app/hook";
import { appConfig } from "config";

interface Props {
  authenticated: boolean;
}

export const CPDetails = ({ authenticated }: Props) => {
  const contactStore = useAppSelector(selectContactDetails);

  const firstNameField = () => {
    return (
      <Input
        name="fname"
        type="text"
        label="First name"
        disabled={true}
        defaultValue={contactStore?.firstname}
        layout="stacked"
      />
    );
  };

  const lastNameField = () => {
    return (
      <Input
        name="lname"
        type="text"
        label="Last name"
        disabled={true}
        defaultValue={contactStore?.lastname}
        layout="stacked"
      />
    );
  };

  const emailField = () => {
    return (
      <Input
        name="emailInput"
        type="email"
        label="Email"
        disabled={true}
        defaultValue={contactStore?.email}
        layout="stacked"
      />
    );
  };

  const phoneField = () => {
    return (
      <Input
        name="telInput"
        type="tel"
        label="Phone"
        disabled={true}
        defaultValue={contactStore?.phone}
        layout="stacked"
      />
    );
  };

  const renderAuthenticated = () => {
    return (
      <>
        <Box marginBottom="md">
          <Columns type="two">
            {firstNameField()}
            {lastNameField()}
          </Columns>
        </Box>
        <Box>
          <Columns type="two">
            {emailField()}
            {phoneField()}
          </Columns>
        </Box>
      </>
    );
  };

  const renderUnauthenticated = () => {
    return <Box>{emailField()}</Box>;
  };

  return (
    <Card size="xxl">
      <Heading variant="xl">My contact details</Heading>
      <Box
        display="flex"
        flexDirection={["column", "column", "row", "row", "row"]}
      >
        <Box>
          <p>
            Your current contact details are displayed below. To modify your
            details, select&nbsp;
            <a
              href={appConfig.CONTACT_DETAILS_BASE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>edit</b>
            </a>
            . When you&apos;ve completed updating your details, select&nbsp;
            <b>save</b>.
          </p>
        </Box>
      </Box>
      {authenticated ? renderAuthenticated() : renderUnauthenticated()}
    </Card>
  );
};
