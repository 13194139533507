import { Checkbox, Box } from "@myob/myob-widgets";
import { useState } from "react";
import { useAppDispatch } from "../../app/hook";
import { toggleAllTopics } from "../../slice/preferencesSlice";

export const CPUnsubscribe = () => {
  const [unsubscribe, setUnsubscribe] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const toggleUnsubscribe = () => {
    dispatch(toggleAllTopics(unsubscribe));
    setUnsubscribe(!unsubscribe);
  };

  return (
    <Box>
      <Checkbox
        name="unsubscribe"
        label="Uncheck all my preferences"
        onChange={() => toggleUnsubscribe()}
        checked={unsubscribe}
      />
    </Box>
  );
};
