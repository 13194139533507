import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";
import {
  Action,
  createAction,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { fetchContactDetailsAPI } from "../api/contactDetailsAPI";
import { IContactDetails, IContactDetailsState } from "../model/contactDetails";
import { FetchStatus } from "../model/fetchStatus";

const initialState: IContactDetailsState = {
  fetchData: null,
  fetchStatus: null,
};

export const fetchContactDetails = createAction(
  "contactDetails/fetch",
  (emailPreferencesId: string) => ({
    payload: {
      emailPreferencesId,
    },
  })
);

const contactDetailsSlice = createSlice({
  name: "contactDetails",
  initialState,
  reducers: {
    contactDetailsPending: (state) => {
      state.fetchStatus = FetchStatus.loading;
    },
    contactDetailsRejected: (state) => {
      state.fetchStatus = FetchStatus.failed;
    },
    contactDetailsFulfilled: (
      state,
      action: PayloadAction<IContactDetails>
    ) => {
      state.fetchStatus = FetchStatus.idle;
      state.fetchData = action.payload;
    },
  },
});

export const {
  contactDetailsPending,
  contactDetailsRejected,
  contactDetailsFulfilled,
} = contactDetailsSlice.actions;

export const fetchContactDetailsEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(fetchContactDetails.match),
    switchMap((action) => {
      return concat(
        of(contactDetailsPending()),
        fetchContactDetailsAPI(action.payload.emailPreferencesId).pipe(
          map((fetchedResponse) =>
            contactDetailsFulfilled(fetchedResponse.response as IContactDetails)
          ),
          catchError((err) => {
            console.log("CPS::fetchContactDetailsEpic::error:" + err);
            return of(contactDetailsRejected());
          })
        )
      );
    })
  );

export const selectContactDetails = (state: RootState) =>
  state.contactDetails.fetchData;
export const selectContactDetailsStatus = (state: RootState) =>
  state.contactDetails.fetchStatus;

export default contactDetailsSlice.reducer;
