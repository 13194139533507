import { Alert, Box } from "@myob/myob-widgets";

interface Props {
  show: boolean;
  dismiss: () => void;
}

export const CPAlert = ({ show, dismiss }: Props) => {
  if (!show) {
    return <></>;
  }
  return (
    <Box marginBottom="md" marginTop="md">
      <Alert
        type="success"
        dismissAfter={null} // Default dismiss after 5 seconds. Empty ensures alert never dismisses
        onDismiss={dismiss}
      >
        <strong>Saved!</strong> Congratulations you&apos;ve successfully updated
        your account preferences.
      </Alert>
    </Box>
  );
};
