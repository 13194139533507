import { ButtonRow, Button, Box } from "@myob/myob-widgets";

interface Props {
  save: () => void;
  cancel: () => void;
}

export const CPFormActions = ({ save, cancel }: Props) => {
  return (
    <Box>
      <ButtonRow>
        <Button HTMLType="button" type="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button HTMLType="submit" type="primary" onClick={save}>
          Save
        </Button>
      </ButtonRow>
    </Box>
  );
};
