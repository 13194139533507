import { from, switchMap } from "rxjs";
import { auth } from "@my-account/tools";
import { fetchAjaxObservable, updateAjaxObservable } from "./cpsAjax";
import { IPreferences } from "../model/preferences";

export const fetchPreferencesAPI = (emailPreferencesId?: string) => {
  if (auth.isLoggedIn()) {
    return from(auth.getToken()).pipe(
      switchMap((token) => {
        return fetchAjaxObservable(token, "/preferences/all");
      })
    );
  } else {
    return fetchAjaxObservable("", `/preferences/emails/${emailPreferencesId}`);
  }
};

export const updatePreferencesAPI = (
  reqBody: IPreferences,
  emailPreferencesId?: string
) => {
  if (auth.isLoggedIn()) {
    return from(auth.getToken()).pipe(
      switchMap((token) => {
        return updateAjaxObservable(token, "/preferences/all", reqBody);
      })
    );
  } else {
    return updateAjaxObservable(
      "",
      `/preferences/emails/${emailPreferencesId}`,
      reqBody
    );
  }
};
