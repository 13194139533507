import { Modal, Button } from "@myob/myob-widgets";

interface Props {
  show: boolean;
  title: string;
  description: string;
  confirm: () => void;
  cancel: () => void;
}

export const CPDialog = ({
  show,
  title,
  description,
  confirm,
  cancel,
}: Props) => {
  if (!show) {
    return <></>;
  }

  return (
    <Modal size="small" title={title} onCancel={cancel}>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancel} type="secondary">
          Cancel
        </Button>
        <Button onClick={confirm} type="delete">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
