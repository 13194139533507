import { FormTemplate } from "@myob/myob-widgets";
import {
  CPDetails,
  CPFooter,
  CPFormActions,
  CPHeader,
  CPTopics,
  CPLoading,
  CPAlert,
  CPDialog,
  CPError,
} from "../../component";
import { useEffect, useState } from "react";

interface Props {
  isLoading: boolean;
  hasPreferences: boolean;
  isLoggedIn: boolean;
  save: () => void;
  saveSuccess: boolean;
}

export const CPView = ({
  isLoading,
  hasPreferences,
  isLoggedIn,
  save,
  saveSuccess,
}: Props) => {
  const [showCancelDialog, setCancelDialog] = useState<boolean>(false);
  const [showAlert, setAlert] = useState<boolean>(false);

  useEffect(() => {
    setAlert(saveSuccess);
  }, [saveSuccess]);

  const dialogConfirm = () => {
    setCancelDialog(false);
    // what happens here? Refresh? Redirect?
    window.location.assign("https://www.myob.com");
  };

  const dialogCancel = () => {
    setCancelDialog(false);
  };

  const alertDismiss = () => {
    setAlert(false);
  };

  const formSave = () => {
    // save form
    save();
  };

  const formCancel = () => {
    setCancelDialog(true);
    // discard form, trigger dialog
  };

  const renderLoading = () => {
    return <CPLoading />;
  };

  const renderView = () => {
    if (hasPreferences) {
      return (
        <>
          <FormTemplate
            sticky="none"
            alert={<CPAlert show={showAlert} dismiss={alertDismiss} />}
            actions={<CPFormActions save={formSave} cancel={formCancel} />}
            pageHead={<CPHeader />}
          >
            <CPDetails authenticated={isLoggedIn} />

            <CPTopics authenticated={isLoggedIn} />

            <CPFooter />

            <CPDialog
              show={showCancelDialog}
              title="Are you sure?"
              description="Any changes you've made will be lost if you don't go back and save them."
              confirm={dialogConfirm}
              cancel={dialogCancel}
            />
          </FormTemplate>
        </>
      );
    }
    return <CPError />;
  };

  return <>{isLoading ? renderLoading() : renderView()}</>;
};
