import { FetchStatus } from "./fetchStatus";

export interface IChannelsPreferences {
  [Channel.email]: boolean;
  [Channel.call]: boolean;
  [Channel.text]: boolean;
}

export interface IPreferences {
  [Topic.tipsTricks]: IChannelsPreferences;
  [Topic.training]: IChannelsPreferences;
  [Topic.offers]: IChannelsPreferences;
  [Topic.survey]: IChannelsPreferences;
  [Topic.research]: IChannelsPreferences;
  [Topic.thirdPartyPromo]: IChannelsPreferences;
}

export interface IPreferencesState {
  fetchData: IPreferences | null;
  fetchStatus: FetchStatus | null;
}

export enum Topic {
  tipsTricks = "myobTipsTricks",
  training = "myobTrainingEvents",
  offers = "myobSpecialoffersPromotions",
  survey = "myobSurvery",
  research = "myobResearch",
  thirdPartyPromo = "myob3rdPartyPromotions",
}

export enum Channel {
  email = "allowEmail",
  call = "allowCall",
  text = "allowText",
}

export interface IChannelPayload {
  topic: Topic;
  channel: Channel;
  value: boolean;
}

export interface ITopicPayload {
  topic: Topic;
  value: boolean;
}
