import { useEffect } from "react";
import { auth } from "@my-account/tools";
import { useAppSelector, useAppDispatch } from "../../app/hook";
import {
  selectPreferences,
  selectPreferenceStatus,
  fetchPreferences,
  updatePreferences,
} from "../../slice/preferencesSlice";
import { selectContactDetailsStatus } from "../../slice/contactDetailsSlice";
import { CPView } from "./CPView";
import { fetchContactDetails } from "../../slice/contactDetailsSlice";
import { FetchStatus } from "../../model/fetchStatus";

const { isLoggedIn } = auth;

export const CP = () => {
  const dispatch = useAppDispatch();

  const getEmailPreferencesId = () => {
    if (isLoggedIn()) return "";

    const urlArray = window.location.pathname.toLowerCase().split("/");

    return urlArray.length > 1 && urlArray[urlArray.length - 2] === "public"
      ? urlArray[urlArray.length - 1]
      : "";
  };

  const emailPreferencesId = getEmailPreferencesId();

  useEffect(() => {
    dispatch(fetchPreferences(emailPreferencesId));
    dispatch(fetchContactDetails(emailPreferencesId));
  }, [dispatch, emailPreferencesId]);

  const preferences = useAppSelector(selectPreferences);

  const hasPreferences = preferences ? true : false;

  const isPreferencesLoading =
    useAppSelector(selectPreferenceStatus) === FetchStatus.loading;
  const isContactDetailsLoading =
    useAppSelector(selectContactDetailsStatus) === FetchStatus.loading;

  const isPageLoading = isPreferencesLoading || isContactDetailsLoading;

  const isPreferencesSaved =
    useAppSelector(selectPreferenceStatus) === FetchStatus.saved;

  const saveForm = () => {
    dispatch(updatePreferences("", preferences));
  };

  return (
    <div>
      <CPView
        isLoading={isPageLoading}
        hasPreferences={hasPreferences}
        isLoggedIn={isLoggedIn()}
        save={saveForm}
        saveSuccess={isPreferencesSaved}
      />
    </div>
  );
};
