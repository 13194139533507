import { from, switchMap } from "rxjs";
import { auth } from "@my-account/tools";
import { fetchAjaxObservable } from "./cpsAjax";

export const fetchContactDetailsAPI = (emailPreferencesId?: string) => {
  if (auth.isLoggedIn()) {
    return from(auth.getToken()).pipe(
      switchMap((token) => {
        return fetchAjaxObservable(token, "/contactdetails");
      })
    );
  } else {
    return fetchAjaxObservable(
      "",
      `/contactdetails/emails/${emailPreferencesId}`
    );
  }
};
