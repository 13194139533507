import { ajax } from "rxjs/ajax";
import { appConfig } from "config";
import { IPreferences } from "../model/preferences";

const getBaseURL = (token: string) => {
  return token
    ? `${appConfig.SECURED_API_BASE_URL}/cps/v1`
    : `${appConfig.PUBLIC_API_BASE_URL}/cps/v1`;
};

const getHeaders = (token: string) => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const fetchAjaxObservable = (token: string, requestUrl: string) =>
  ajax({
    url: getBaseURL(token) + requestUrl,
    method: "GET",
    headers: getHeaders(token),
    responseType: "json",
  });

export const updateAjaxObservable = (
  token: string,
  requestUrl: string,
  requestBody: IPreferences | null
) =>
  ajax({
    url: getBaseURL(token) + requestUrl,
    method: "PUT",
    headers: getHeaders(token),
    responseType: "json",
    body: requestBody,
  });
