import { Box, InfoIcon } from "@myob/myob-widgets";

export const CPFooter = () => {
  return (
    <Box as="footer" margin={["", "", "xxl", "xxl", "xxl"]}>
      <Box as="p">
        <Box as="i" verticalAlign="middle">
          <InfoIcon title="info" />
        </Box>
        <b>&nbsp;Please allow 48 hours for these settings to take effect.</b>
      </Box>
      <p>
        Important information: by updating these preferences you will be
        updating the preferences of anyone in your business that uses these
        contact details.
      </p>
      <p>
        You will continue to receive important account information, invoices,
        and notifications on any changes to your product or it&apos;s terms of
        use, regardless of your communication preferences.
      </p>
      <p>
        View the&nbsp;
        <a
          href="http://myob.com.au/privacy-disclosure"
          target="_blank"
          rel="noopener noreferrer"
        >
          MYOB Privacy Disclosure Statement
        </a>
        &nbsp;for AU and&nbsp;
        <a
          href="http://myob.co.nz/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          MYOB Group Privacy Statement
        </a>
        &nbsp;for NZ.
      </p>
    </Box>
  );
};
