import {
  Box,
  Card,
  PageState,
  Button,
  Heading,
  RefreshIcon,
} from "@myob/myob-widgets";
// @ts-ignore
import errorLogo from "../assets/something-went-wrong.svg";

export const CPError = () => {
  return (
    <Box padding="lg" backgroundColor="#ebeef1" data-testid="error">
      <Card>
        <PageState
          title="Something went wrong"
          actions={[
            <Button
              key={1}
              type="link"
              icon={<RefreshIcon />}
              onClick={() => window.location.reload()}
            >
              Refresh browser
            </Button>,
          ]}
          description={
            <Heading
              variant="sm"
              marginTop="lg"
              marginBottom="xTiny"
              textAlign="center"
            >
              If the issue persists, please contact MYOB support.
            </Heading>
          }
          image={<img src={errorLogo} alt="something went wrong" />}
        />
      </Card>
    </Box>
  );
};
