import { CP } from "./page/preference/CP";
import { Provider } from "react-redux";
import { store } from "./app/store";

export const Root = () => {
  return (
    <Provider store={store}>
      <CP />
    </Provider>
  );
};
