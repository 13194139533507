import { CPTopic } from "./CPTopic";
import { Card, Separator, Heading } from "@myob/myob-widgets";
import { Topic } from "../../model/preferences";
import { CPUnsubscribe } from "./CPUnsubscribe";

interface Props {
  authenticated: boolean;
}

export const CPTopics = ({ authenticated }: Props) => {
  return (
    <>
      <Card size="xxl">
        <Heading variant="xl">Topics I&apos;m interested in</Heading>
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Tips &amp; Tricks"
          description="Get the most out of your subscription - we'll keep you informed on updates to your product, new features, relevant business advice and industry insights from the experts"
          topic={Topic.tipsTricks}
        />
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Training &amp; Events"
          description="Receive exclusive invites to events, free online training and webinars, and thought leadership and networking seminars"
          topic={Topic.training}
        />
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Promotions"
          description="Stay on top of MYOB's latest products and exclusive offers - including access to discounts, special offers and information about other MYOB products or services that might benefit you."
          topic={Topic.offers}
        />
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Surveys"
          description="	
          Your feedback helps us serve you better - Receive invites to surveys covering a variety of topics relevant to MYOB, carried out by, or on behalf of MYOB"
          topic={Topic.survey}
        />
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Research"
          description="Help form what our future product and service initiatives look like - you may be invited to participate in market research carried out by, or on behalf of MYOB"
          topic={Topic.research}
        />
        <Separator />
        <CPTopic
          authenticated={authenticated}
          title="Third-Party Promotions"
          description="Benefit from third-party promotions - Receive special offers and product information relevant to you and your business, from MYOB or directly from our third-party partners.  Don't worry, we'll never share your information with the third-p.arty without your consent"
          topic={Topic.thirdPartyPromo}
        />
        <Separator />

        <CPUnsubscribe />
      </Card>
    </>
  );
};
