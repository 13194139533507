import { Checkbox, CheckboxGroup, Box } from "@myob/myob-widgets";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import {
  selectPreferences,
  toggleTopic,
  toggleChannel,
} from "../../slice/preferencesSlice";
import { IPreferences, Channel, Topic } from "../../model/preferences";
import { selectContactDetails } from "slice/contactDetailsSlice";

interface Props {
  authenticated: boolean;
  title: string;
  description: string;
  topic: Topic;
}

export const CPTopic = ({
  authenticated,
  title,
  description,
  topic,
}: Props) => {
  const dispatch = useAppDispatch();

  const contactStore = useAppSelector(selectContactDetails);

  const phoneExists = !!contactStore.phone;

  const prefStore =
    useAppSelector(selectPreferences)[topic as keyof IPreferences];

  const emailCheckbox = prefStore.allowEmail;
  const phoneCheckbox = prefStore.allowCall;
  const smsCheckbox = prefStore.allowText;

  let TopicToggleCheckbox = false;

  if (emailCheckbox || phoneCheckbox || smsCheckbox) TopicToggleCheckbox = true;

  const renderEmailCheckbox = () => {
    return (
      <Checkbox
        name="email"
        label="Email"
        onChange={() =>
          dispatch(
            toggleChannel({
              topic: topic,
              channel: Channel.email,
              value: !emailCheckbox,
            })
          )
        }
        checked={emailCheckbox}
      />
    );
  };

  const renderPhoneCheckbox = () => {
    return (
      <Checkbox
        name="phone"
        label="Phone"
        onChange={() =>
          dispatch(
            toggleChannel({
              topic: topic,
              channel: Channel.call,
              value: !phoneCheckbox,
            })
          )
        }
        checked={phoneCheckbox}
        disabled={!phoneExists}
      />
    );
  };

  const renderSMSCheckbox = () => {
    return (
      <Checkbox
        name="sms"
        label="SMS"
        onChange={() =>
          dispatch(
            toggleChannel({
              topic: topic,
              channel: Channel.text,
              value: !smsCheckbox,
            })
          )
        }
        checked={smsCheckbox}
        disabled={!phoneExists}
      />
    );
  };

  const renderAuthenticated = () => {
    return (
      <>
        <Box display="flex">
          <Box paddingRight="md" paddingTop={["md", "md"]}>
            {renderEmailCheckbox()}
          </Box>
          <Box paddingRight="md" paddingTop={["md", "md"]}>
            {renderPhoneCheckbox()}
          </Box>
          <Box paddingRight="md" paddingTop={["md", "md"]}>
            {renderSMSCheckbox()}
          </Box>
        </Box>
      </>
    );
  };

  const renderUnauthenticated = () => {
    return <Box paddingTop={["md", "md"]}>{renderEmailCheckbox()}</Box>;
  };

  return (
    <Box>
      <CheckboxGroup
        label={title}
        layout="stacked"
        renderCheckbox={() => (
          <Box
            display="flex"
            flexDirection={["column", "column", "row", "row", "row"]}
          >
            <Box marginRight={["", "", "2xl", "2xl", "2xl"]}>
              <Checkbox
                name="group"
                label={description}
                onChange={() =>
                  dispatch(
                    toggleTopic({ topic: topic, value: !TopicToggleCheckbox })
                  )
                }
                checked={TopicToggleCheckbox}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              marginLeft={["", "", "auto", "auto", "auto"]}
            >
              {authenticated ? renderAuthenticated() : renderUnauthenticated()}
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};
