import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";
import {
  Action,
  createAction,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
  fetchPreferencesAPI,
  updatePreferencesAPI,
} from "../api/preferencesAPI";
import {
  IPreferences,
  IPreferencesState,
  IChannelsPreferences,
  IChannelPayload,
  ITopicPayload,
} from "../model/preferences";
import { FetchStatus } from "../model/fetchStatus";

const initialState: IPreferencesState = {
  fetchData: null,
  fetchStatus: null,
};

export const fetchPreferences = createAction(
  "preferences/fetch",
  (emailPreferencesId: string) => ({
    payload: {
      emailPreferencesId,
    },
  })
);

export const updatePreferences = createAction(
  "preferences/update",
  (emailPreferencesId: string, reqPreferencesBody: IPreferences) => ({
    payload: {
      emailPreferencesId,
      reqPreferencesBody,
    },
  })
);

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    preferencesPending: (state) => {
      state.fetchStatus = FetchStatus.loading;
    },
    preferencesRejected: (state) => {
      state.fetchStatus = FetchStatus.failed;
    },
    preferencesFulfilled: (state, action: PayloadAction<IPreferences>) => {
      state.fetchStatus = FetchStatus.idle;
      state.fetchData = action.payload;
    },
    preferencesUpdated: (state, action: PayloadAction<IPreferences>) => {
      state.fetchStatus = FetchStatus.saved;
      state.fetchData = action.payload;
    },
    toggleAllTopics: (state, action: PayloadAction<boolean>) => {
      for (const topic in state.fetchData) {
        const pref = state.fetchData[topic as keyof IPreferences];
        for (const value in pref) {
          pref[value as keyof IChannelsPreferences] = action.payload;
        }
      }
    },
    toggleTopic: (state, action: PayloadAction<ITopicPayload>) => {
      const topic = action.payload.topic;
      const pref = state.fetchData[topic as keyof IPreferences];
      for (const value in pref) {
        pref[value as keyof IChannelsPreferences] = action.payload.value;
      }
    },
    toggleChannel: (state, action: PayloadAction<IChannelPayload>) => {
      const topic = action.payload.topic;
      const channel = action.payload.channel;
      state.fetchData[topic as keyof IPreferences][
        channel as keyof IChannelsPreferences
      ] = action.payload.value;
    },
  },
});

export const {
  preferencesPending,
  preferencesRejected,
  preferencesFulfilled,
  preferencesUpdated,
  toggleAllTopics,
  toggleTopic,
  toggleChannel,
} = preferencesSlice.actions;

export const fetchPreferencesEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(fetchPreferences.match),
    switchMap((action) => {
      return concat(
        of(preferencesPending()),
        fetchPreferencesAPI(action.payload.emailPreferencesId).pipe(
          map((fetchedResponse) =>
            preferencesFulfilled(fetchedResponse.response as IPreferences)
          ),
          catchError((err) => {
            console.log("CPS::fetchPreferencesEpic::error:" + err);
            return of(preferencesRejected());
          })
        )
      );
    })
  );

export const updatePreferencesEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(updatePreferences.match),
    switchMap((action) => {
      return concat(
        of(preferencesPending()),
        updatePreferencesAPI(
          action.payload.reqPreferencesBody,
          action.payload.emailPreferencesId
        ).pipe(
          map((fetchedResponse) =>
            preferencesUpdated(fetchedResponse.response as IPreferences)
          ),
          catchError((err) => {
            console.log("CPS::updatePreferencesEpic::error:" + err);
            return of(preferencesRejected());
          })
        )
      );
    })
  );

export const selectPreferences = (state: RootState) =>
  state.commPreferences.fetchData;
export const selectPreferenceStatus = (state: RootState) =>
  state.commPreferences.fetchStatus;

export default preferencesSlice.reducer;
